/**
 * @typedef {Object} FetchMoreResultsProps
 * @property {String} queryName the graphQL query name as defined in the GRAPHQL_QUERY_NAME constant
 * @property {Function} fetchMore the fetchMore function from the response of useQuery
 * @property {Object} pageInfo the pageInfo object that holds the hasNextPage and endCursor
 * @property {Function} updateQuery function that merges the new data with the existing data
 */

/**
 * Helper function to fetch more graphQL data for paginated queries
 * @param {FetchMoreResultsProps} FetchMoreResultsProps
 */
export const fetchMoreResults = ({ fetchMore, pageInfo, updateQuery }) => {
  const { hasNextPage, endCursor } = pageInfo;

  if (!hasNextPage) {
    return;
  }

  fetchMore({
    variables: {
      after: endCursor,
    },
    updateQuery,
  });
};
