import React from 'react';
import CreditsPaymentSuccessDialog from 'components/dialogues/CreditsPaymentSuccessDialog';
import { withRouter } from 'react-router';
import { path } from 'ramda';
import { shape, string, func } from 'prop-types';

const numberOfCredits = path(['query', 'numberOfCredits']);

const PaymentSuccessDialog = ({ location, router }) => (
  <CreditsPaymentSuccessDialog
    onClose={() => {
      router.push('/kiosk/issue');
    }}
    numberOfCredits={numberOfCredits(location)}
  />
);

PaymentSuccessDialog.propTypes = {
  router: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    query: {
      numberOfCredits: string,
    }.isRequired,
  }),
};

PaymentSuccessDialog.defaultProps = {
  location: {
    query: {
      numberOfCredits: undefined,
    },
  },
};

export default withRouter(PaymentSuccessDialog);
