import React from 'react';
import { func } from 'prop-types';
import styledModule from 'helpers/styledModule';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);
const NavItem = styledModule.div(CSS.navItem);
const BackButton = styledModule(NavItem)(CSS.backButton);
const MoreFromButton = styledModule(NavItem)(CSS.moreFromButton);

const VerticalTilePaneNavigation = ({ onBack, onMoreFromProvider }) => (
  <Container>
    <BackButton onClick={onBack} />
    <MoreFromButton onClick={onMoreFromProvider} />
  </Container>
);

VerticalTilePaneNavigation.propTypes = {
  onBack: func.isRequired,
  onMoreFromProvider: func.isRequired,
};

export default VerticalTilePaneNavigation;
