import React from 'react';
import styledModule from 'helpers/styledModule';
import { node } from 'prop-types';
import CSS from './styles.scss';

const Wrapper = styledModule.main(CSS.wrapper);

const RecentIssuesPage = ({ children }) => <Wrapper>{children}</Wrapper>;

RecentIssuesPage.propTypes = {
  children: node.isRequired,
};

export default RecentIssuesPage;
