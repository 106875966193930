import Analytics from 'instances/analytics';
import CreditsKioskNavigationActions from 'actions/CreditsKioskNavigationActions';
import { history } from 'byebye';
import { INTERNAL_LOCATION, DISCOVER_PAGE_TYPE } from 'app-constants';

const baseRoute = '/kiosk/issue';

const navigate = (page) =>
  history.navigate(page, {
    trigger: true,
  });

export const updateCurrentPageAndOpenProviderArchive = ({ provider, issue }) => {
  CreditsKioskNavigationActions.updateCurrentPage({
    issue: {
      bnlId: issue.bnlId,
      id: issue.id,
    },
    provider: {
      providerId: provider.providerId,
      id: provider.id,
    },
  });

  Analytics.track('Issue Archive Opened', {
    internal_location: INTERNAL_LOCATION.PAGED_ISSUE_VIEW,
    issue_id: issue.bnlId,
    provider_id: provider.providerId,
  });

  navigate(`${baseRoute}/${provider.id}`);
};

export const onBackFromIssueView = (previousPage) => {
  const { provider } = previousPage;
  CreditsKioskNavigationActions.removeLastPage();

  navigate(provider ? `${baseRoute}/${provider.id}` : baseRoute);
};

export const saveCurrentPage = () => {
  CreditsKioskNavigationActions.saveCurrentPage({
    type: DISCOVER_PAGE_TYPE.ISSUE,
  });
};
