import React from 'react';
import Spinner from 'components/Loading';
import styledModule from 'helpers/styledModule';
import CSS from './style.scss';

const LoadingWrapper = styledModule.div(CSS.loadingWrapper);
const StyledSpinner = styledModule(Spinner)(CSS.spinner);

const Loader = () => (
  <LoadingWrapper>
    <StyledSpinner />
  </LoadingWrapper>
);

export default Loader;
