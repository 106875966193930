import { useState } from 'react';
import TilesStore from 'stores/TilesStore';
import useAlt from 'hooks/useAlt';
import { getTimelineTiles } from 'selectors/tiles';
import PremiumIssueActions from 'actions/PremiumIssueActions';
import { remove, head, isEmpty } from 'ramda';
import PremiumIssueStore from 'stores/PremiumIssueStore';
import { STATUS_INITIAL } from 'app-constants';
import useUser from 'hooks/useUser';
import { createPages } from './helpers/createPages';

const removeFirstPage = remove(0, 1);

const useIssueTiles = ({ itemIds, bnlId }) => {
  const [pages, setPages] = useState(createPages(itemIds));
  const user = useUser();
  const tiles = useAlt([TilesStore], ([tilesState]) => tilesState.tiles);
  const { status } = useAlt(
    [PremiumIssueStore],
    ([{ tilesStatus }]) => tilesStatus.get(bnlId) || { status: STATUS_INITIAL },
  );

  const fetchNextPage = () => {
    if (!isEmpty(pages)) {
      PremiumIssueActions.fetchTiles({
        userId: user.id,
        itemIds: head(pages),
        bnlId,
      });

      setPages(prevPages => removeFirstPage(prevPages));
    }
  };

  return {
    tiles: getTimelineTiles(tiles, itemIds),
    status,
    fetchNextPage,
  };
};

export default useIssueTiles;
