// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".navItem-mVOu-{font-size:13px;font-weight:400;color:#948784;font-family:\"Inter\",sans-serif;display:flex;padding-left:10px;padding-right:10px;cursor:pointer}.navItem-mVOu-:before{color:#948784;margin:0 7px 0 0;position:relative}.navItem-mVOu-:hover{color:#333}.navItem-mVOu-:hover:before{color:#333}\n", ""]);
// Exports
exports.locals = {
	"navItem": "navItem-mVOu-"
};
module.exports = exports;
