import React from 'react';
import { Dialog, DialogBody, Button } from '@blendle/lego';
import Confetti from 'components/Confetti';
import styledModule from 'helpers/styledModule';
import { translate } from 'instances/i18n';
import { func, string, bool } from 'prop-types';
import CSS from './styles.scss';

const StyledConfetti = styledModule(Confetti)(CSS.confetti);
const Title = styledModule.h1(CSS.title);
const Paragraph = styledModule.p(CSS.paragraph);
const StyledButton = styledModule(Button)(CSS.button);
const Intro = styledModule.div(CSS.intro);
const FadeOut = styledModule.div(CSS.fadeOut);

const CreditsPaymentSuccessDialog = ({ numberOfCredits, onClose, isOpen }) => (
  <Dialog onClose={onClose} open={isOpen}>
    <Intro>
      <StyledConfetti />
      <Title>{translate('payment.credits.success.title')}</Title>
      <FadeOut />
    </Intro>
    <DialogBody>
      <Paragraph>{translate('payment.credits.success.body', [numberOfCredits])}</Paragraph>
      <StyledButton color="razzmatazz" onClick={onClose}>
        {translate('payment.credits.success.cta')}
      </StyledButton>
    </DialogBody>
  </Dialog>
);

CreditsPaymentSuccessDialog.propTypes = {
  numberOfCredits: string.isRequired,
  onClose: func.isRequired,
  isOpen: bool,
};

CreditsPaymentSuccessDialog.defaultProps = {
  isOpen: true,
};

export default CreditsPaymentSuccessDialog;
