// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog-3Ww3A{background:rgba(255,255,255,0.95)}@media (max-width: 600px){.dialog-3Ww3A{background:#fff}}.dialogBody-3YSJU{display:flex;justify-content:center;flex-direction:column;padding:22px}.innerBody-2Zrjw{max-width:400px;width:100%;margin:0 auto;overflow-y:auto}.illustration-3U-hi{margin:0 auto 160px}.copyWrapper-3IaTR{position:relative}@media (max-width: 600px){.copyWrapper-3IaTR{padding-bottom:100px}}.title-uuCdl{font-family:\"GT-Walsheim\",sans-serif;font-size:40px;line-height:42px;letter-spacing:-0.05em;color:#000;position:absolute;width:100%;top:254px}@media (max-width: 600px){.title-uuCdl{font-size:28px}}.text-xhclo{font-family:\"GT-Walsheim\",sans-serif;font-size:17px;line-height:22px;letter-spacing:-0.02em;color:#000;margin-bottom:18px;position:absolute;width:100%;top:301px}@media (max-width: 600px){.text-xhclo{font-size:16px;line-height:22px}}.ctaWrapper-1F0AX{padding-top:7px}@media (max-width: 600px){.ctaWrapper-1F0AX{position:absolute;bottom:0;left:0;right:0;padding:16px 22px 22px;background-color:#fafafa}}.cta-cGcQs{width:100%;max-width:167px;font-weight:500;font-size:16px;padding:10px 10px}@media (max-width: 600px){.cta-cGcQs{max-width:100%;box-shadow:0 2px 2px rgba(0,0,0,0.15),0 0 2px rgba(0,0,0,0.05)}}\n", ""]);
// Exports
exports.locals = {
	"dialog": "dialog-3Ww3A",
	"dialogBody": "dialogBody-3YSJU",
	"innerBody": "innerBody-2Zrjw",
	"illustration": "illustration-3U-hi",
	"copyWrapper": "copyWrapper-3IaTR",
	"title": "title-uuCdl",
	"text": "text-xhclo",
	"ctaWrapper": "ctaWrapper-1F0AX",
	"cta": "cta-cGcQs"
};
module.exports = exports;
