import { gql } from 'apollo-boost';
import { GlobalFragments, IssueFragments } from 'queries/fragments';
import { DEFAULT_ISSUES_PER_PAGE } from 'app-constants';

export const KIOSK_ISSUES_QUERY = gql`
  query KioskIssues($first: Int = ${DEFAULT_ISSUES_PER_PAGE}, $after: String) {
    kioskIssues(first: $first, after: $after) {
      pageInfo {
        ...pageInfoFragment
      }
      nodes {
        ...issueFragment
      }
    }
  }
  ${GlobalFragments.pageInfo}
  ${IssueFragments.issue}
`;

export const MULTIPLE_CATEGORIES_ISSUES_QUERY = gql`
  query MultiLanguageIssues($first: Int = ${DEFAULT_ISSUES_PER_PAGE}) {
    dutchIssues: kioskIssues(language: [nl_NL], first: $first) {
      nodes {
        ...issueFragment
      }
    }
    englishIssues: kioskIssues(language: [en_US], first: $first) {
      nodes {
        ...issueFragment
      }
    }
    germanIssues: kioskIssues(language: [de_DE], first: $first) {
      nodes {
        ...issueFragment
      }
    }
  }

  ${IssueFragments.issue}
`;
