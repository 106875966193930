import React from 'react';
import CreditsKioskNavigationActions from 'actions/CreditsKioskNavigationActions';
import RecentIssuesContainer from 'containers/discover/RecentIssuesContainer';
import { INTERNAL_LOCATION, GRAPHQL_QUERY_NAME } from 'app-constants';
import { bool } from 'prop-types';
import useStorePages from 'hooks/useStorePages';
import CreditsKioskNavigationStore from 'stores/CreditsKioskNavigationStore';
import { KIOSK_ISSUES_QUERY } from '../queries';
import IntroTile from '../components/shared/IntroTile';

const SingleCategoryRecentIssuesContainer = ({
  showPaymentSuccessDialog,
  showNewCreditsKioskDialog,
}) => {
  const { currentPage } = useStorePages(CreditsKioskNavigationStore);

  const lockHorizontalScroll = showNewCreditsKioskDialog || showPaymentSuccessDialog;

  return (
    <RecentIssuesContainer
      baseRoute="kiosk/issue"
      query={KIOSK_ISSUES_QUERY}
      queryName={GRAPHQL_QUERY_NAME.KIOSK_ISSUES}
      internalLocation={INTERNAL_LOCATION.CREDIT_KIOSK}
      mountEventName="Credit Kiosk Opened"
      pageEventName="Credit Kiosk"
      saveRecentIssuesPage={CreditsKioskNavigationActions.saveRecentIssuesPage}
      data-testid="recent-issues"
      introTile={<IntroTile />}
      lockHorizontalScroll={lockHorizontalScroll}
      scrollPosition={currentPage.scrollPosition}
    />
  );
};

SingleCategoryRecentIssuesContainer.propTypes = {
  showPaymentSuccessDialog: bool.isRequired,
  showNewCreditsKioskDialog: bool.isRequired,
};

export default SingleCategoryRecentIssuesContainer;
