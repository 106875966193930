import React from 'react';
import { string, shape, func } from 'prop-types';
import styledModule from 'helpers/styledModule';
import useFormattedPublicationDate from 'hooks/useFormattedPublicationDate';
import CSS from './styles.scss';

const Tile = styledModule.div(CSS.tile);
const Cover = styledModule.img(CSS.cover);
const PublicationDate = styledModule.div(CSS.publicationDate);

const IssueCover = ({ publicationDate, onClick, coverImage }) => {
  const { url } = coverImage;

  const formattedPublicationDate = useFormattedPublicationDate(publicationDate);

  return (
    <Tile>
      <Cover
        onClick={onClick}
        src={`${url}?auto=compress,format&h=248`}
        data-testid="tile-cover-img"
      />
      <PublicationDate>{formattedPublicationDate}</PublicationDate>
    </Tile>
  );
};

IssueCover.propTypes = {
  coverImage: shape({
    url: string.isRequired,
  }).isRequired,
  onClick: func.isRequired,
  publicationDate: string.isRequired,
};

export default IssueCover;
