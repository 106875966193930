// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-2jnY1{background-color:#fff;box-shadow:0 1px 0 rgba(0,0,0,0.1);height:44px;line-height:44px;display:flex;vertical-align:top}.backButton-ALQ80{padding-left:20px}.backButton-ALQ80:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA06\";font-size:18px}.moreFromButton-3wsaY:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA0A\";font-size:16px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2jnY1",
	"backButton": "backButton-ALQ80",
	"moreFromButton": "moreFromButton-3wsaY"
};
module.exports = exports;
