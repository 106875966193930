// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-3sWYT{margin-top:52px;max-width:545px;padding-left:16px}.label-uz_18.label-uz_18{margin-left:16px}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-3sWYT",
	"label": "label-uz_18"
};
module.exports = exports;
