// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes hide-dropdown-1fNcx{from{transform:scale(1) translateY(0)}99%{transform:scale(1) translateY(-5px)}to{transform:scale(0) translateY(-5px)}}@keyframes show-dropdown-2u9BN{from{transform:scale(1) translateY(-5px)}to{transform:scale(1) translateY(0)}}@keyframes hide-dropdown-from-bottom-1GeYj{from{transform:scale(1) translateY(0)}99%{transform:scale(1) translateY(5px)}to{transform:scale(0) translateY(5px)}}@keyframes show-dropdown-from-bottom-XzjT9{from{transform:scale(1) translateY(5px)}to{transform:scale(1) translateY(0)}}.isActive-3zLgs.isActive-3zLgs{color:#333}.isActive-3zLgs svg{transform:rotate(-180deg)}.arrowDownIcon-1M9X-{position:relative;top:19px;left:5px}.dropdownWrapper-1fqlq{position:relative}.dropdownList-3jAq_{z-index:1;top:44px;box-shadow:0 1px 2px rgba(148,135,132,0.25);background:#fff;position:absolute;left:0;column-gap:0}.dropdownList-3jAq_ li{break-inside:avoid-column;-webkit-column-break-inside:avoid}.isClosed-BZbxW{opacity:0;transition:opacity 0.1s;transform-origin:top left;animation:hide-dropdown-1fNcx 0.1s ease-out;animation-fill-mode:forwards}.isOpen-3t1A2{opacity:1;transition:opacity 0.2s;animation:show-dropdown-2u9BN 0.2s ease-out;animation-fill-mode:forwards}.sectionLink-1ps0j{font-family:\"Inter\",sans-serif;transition:color 0.05s ease;color:#948784;padding:12px 16px;overflow:hidden;font-size:13px;text-overflow:ellipsis;white-space:nowrap;text-decoration:none;display:block;cursor:pointer}.sectionLink-1ps0j:hover{color:#333}\n", ""]);
// Exports
exports.locals = {
	"isActive": "isActive-3zLgs",
	"arrowDownIcon": "arrowDownIcon-1M9X-",
	"dropdownWrapper": "dropdownWrapper-1fqlq",
	"dropdownList": "dropdownList-3jAq_",
	"isClosed": "isClosed-BZbxW",
	"hide-dropdown": "hide-dropdown-1fNcx",
	"isOpen": "isOpen-3t1A2",
	"show-dropdown": "show-dropdown-2u9BN",
	"sectionLink": "sectionLink-1ps0j",
	"hide-dropdown-from-bottom": "hide-dropdown-from-bottom-1GeYj",
	"show-dropdown-from-bottom": "show-dropdown-from-bottom-XzjT9"
};
module.exports = exports;
