import React, { Component } from 'react';
import { wrapDisplayName } from '@blendle/recompose';
import CSS from './style.scss';

export default (ComposedComponent) =>
  class ResetStyles extends Component {
    static displayName = wrapDisplayName(ComposedComponent, 'resetStyles');

    componentDidMount() {
      document.body.classList.add(CSS.reset);
    }

    componentWillUnmount() {
      document.body.classList.remove(CSS.reset);
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  };
