// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-2smEt{from{opacity:0}to{opacity:1}}.tile-1f-s9{flex:none;padding-right:16px;opacity:0;animation:fadeIn-2smEt 0.3s 0s ease-in forwards}.cover-2AubB{box-shadow:0 4px 10px rgba(0,0,0,0.2);border-radius:2px;cursor:pointer;height:242px}@media (min-width: 600px){.cover-2AubB{height:248px}}@media (hover: hover){.cover-2AubB:hover{box-shadow:0 6px 10px rgba(0,0,0,0.25);opacity:0.9}}.publicationDate-3D1UB{font-family:\"Inter\",sans-serif;font-size:12px;color:#5b5b5b;margin-top:10px}.publicationDate-3D1UB:first-letter{text-transform:capitalize}\n", ""]);
// Exports
exports.locals = {
	"tile": "tile-1f-s9",
	"fadeIn": "fadeIn-2smEt",
	"cover": "cover-2AubB",
	"publicationDate": "publicationDate-3D1UB"
};
module.exports = exports;
