import React from 'react';
import { withErrorBoundary } from 'higher-order-components/withErrorBoundary';
import withRouter from 'react-router/lib/withRouter';
import { shape, string } from 'prop-types';
import { compose } from 'ramda';
import IssueContainer from 'containers/discover/IssueContainer';
import useInitialValueOnly from 'hooks/useInitialValueOnly';
import CreditsKioskNavigationStore from 'stores/CreditsKioskNavigationStore';
import {
  updateCurrentPageAndOpenProviderArchive,
  onBackFromIssueView,
  saveCurrentPage,
} from '../helpers/navigation';

const IssueRootContainer = ({ params }) => {
  const graphQLIssueId = useInitialValueOnly(params.graphQLIssueId);

  return (
    <IssueContainer
      graphQLIssueId={graphQLIssueId}
      data-testid="issue"
      saveCurrentPage={saveCurrentPage}
      updateCurrentPageAndOpenProviderArchive={updateCurrentPageAndOpenProviderArchive}
      onBackFromIssueView={onBackFromIssueView}
      store={CreditsKioskNavigationStore}
    />
  );
};

IssueRootContainer.propTypes = {
  params: shape({
    graphQLIssueId: string,
  }).isRequired,
};

const enhance = compose(withErrorBoundary, withRouter);

export default enhance(IssueRootContainer);
