import { last, dropLast, pipe } from 'ramda';
import useAlt from 'hooks/useAlt';

const dropLastPage = dropLast(1);

/**
 * Return the current and previous page from either the CreditsKioskNavigation- or DiscoverNavigationStore
 * @param {store} store Instance of CreditsKioskNavigationStore or DiscoverNavigationStore
 */
const useStorePages = (store) => {
  const { pages, isIssuePageActive } = useAlt([store], ([state]) => state);

  return {
    currentPage: last(pages),
    previousPage: pipe(dropLastPage, last)(pages),
    isIssuePageActive,
  };
};

export default useStorePages;
