import React, { useEffect, useMemo } from 'react';
import { shape, string, arrayOf, func, bool } from 'prop-types';
import BrowserEnv from 'instances/browser_environment';
import { isError, isInitial, isPending, isOk } from 'helpers/status';
import { translateElement } from 'instances/i18n';
import TilesIssuePage from 'components/discover/TilesIssuePage';
import SubNavBarNavigation from 'components/discover/SubNavBarNavigation';
import useIssueTiles from 'hooks/useIssueTiles';

const TilesIssueContainer = ({
  issue,
  provider,
  onBackFromIssueView,
  updateCurrentPageAndOpenProviderArchive,
  isActive,
}) => {
  const { bnlId, itemIds } = issue;
  const { tiles, status, fetchNextPage } = useIssueTiles({ itemIds, bnlId });
  const [isMobile, isDesktop] = useMemo(() => [BrowserEnv.isMobile(), BrowserEnv.isDesktop()], []);

  useEffect(() => {
    fetchNextPage();
  }, [itemIds, bnlId]);

  if (isError(status)) {
    throw new Error('Failed fetching issue', bnlId);
  }

  const orientation = isMobile ? 'vertical' : 'horizontal';

  const onNearEnd = () => {
    if (isOk(status)) {
      fetchNextPage();
    }
  };

  const navigation = (
    <SubNavBarNavigation
      onBack={onBackFromIssueView}
      onMoreFromProvider={() => {
        updateCurrentPageAndOpenProviderArchive({ issue, provider });
      }}
      moreFromProviderCopy={translateElement('app.issue.more_from_provider', {
        provider: provider.name,
      })}
      date-testid="navigation"
    />
  );

  const isLoading = isInitial(status) || isPending(status);

  return (
    <>
      {!isMobile ? navigation : null}
      <TilesIssuePage
        loading={isLoading}
        tiles={tiles}
        onNearEnd={onNearEnd}
        orientation={orientation}
        showButtons={isDesktop}
        mobileNavigation={isMobile ? navigation : null}
        date-testid="tiles-issue-page"
        isActive={isActive}
      />
    </>
  );
};

TilesIssueContainer.propTypes = {
  provider: shape({
    id: string.isRequired,
    name: string.isRequired,
    providerId: string.isRequired,
  }).isRequired,
  issue: shape({
    id: string.isRequired,
    bnlId: string.isRequired,
    itemIds: arrayOf(string).isRequired,
  }).isRequired,
  onBackFromIssueView: func.isRequired,
  updateCurrentPageAndOpenProviderArchive: func.isRequired,
  isActive: bool.isRequired,
};

export default TilesIssueContainer;
