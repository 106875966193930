import React, { Component } from 'react';

import Error from 'components/Application/Error';

// Simple helper function to make testing of components that need to throw an error easier
export function throwError(error) {
  throw error;
}

export function withErrorBoundary(WrappedComponent) {
  return class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error, stackTrace) {
      window.ErrorLogger.captureException(error, { extra: stackTrace });
    }

    render() {
      const { hasError } = this.state;

      if (hasError) {
        return <Error {...this.props} />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}
