import React, { useRef, useEffect } from 'react';
import {
  FadeOut,
  useHorizontalScrollFadeOutHandler,
  PointerLeftIcon,
  PointerRightIcon,
} from '@blendle/lego';
import { func, shape, string, number, arrayOf, node } from 'prop-types';

import styledModule from 'helpers/styledModule';
import CSS from './styles.scss';
import IssueCover from './IssueCover';

const Container = styledModule.div(CSS.container);
Container.displayName = 'Container';

const Header = styledModule.div(CSS.header);
Header.displayName = 'Header';

const Title = styledModule.h2(CSS.title);
Title.displayName = 'Title';

const ScrollWrapper = styledModule.div(CSS.scrollWrapper);
ScrollWrapper.displayName = 'ScrollWrapper';

const ScrollButton = styledModule.button(CSS.scrollButton);
ScrollButton.displayName = 'ScrollButton';

const ScrollLeft = styledModule(ScrollButton)(CSS.scrollLeft);
ScrollLeft.displayName = 'ScrollLeft';

const ScrollRight = styledModule(ScrollButton)(CSS.scrollRight);
ScrollRight.displayName = 'ScrollRight';

const PointerLeft = styledModule(PointerLeftIcon)(CSS.pointer);
PointerLeft.displayName = 'PointerLeft';

const PointerRight = styledModule(PointerRightIcon)(CSS.pointer);
PointerRight.displayName = 'PointerRight';

const PIXELS_TO_SCROLL = 450;

const HorizontalIssueScroll = ({ title, onIssueClick, initialScrollPosition, issues }) => {
  const { fadeOutLeft, fadeOutRight, onScroll } = useHorizontalScrollFadeOutHandler();
  const coverRowRef = useRef();

  const smoothScrollTo = (position) => {
    coverRowRef.current.scrollTo({
      left: position,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    coverRowRef.current.scrollLeft = initialScrollPosition;
  }, []);

  const scrollLeft = () => {
    smoothScrollTo(coverRowRef.current.scrollLeft - PIXELS_TO_SCROLL);
  };

  const scrollRight = () => {
    smoothScrollTo(coverRowRef.current.scrollLeft + PIXELS_TO_SCROLL);
  };

  const onClick = (issue) => {
    if (onIssueClick) {
      onIssueClick(issue);
    }
  };

  return (
    <Container data-testid="horizontal-issue-scroll">
      <Header>
        <Title>{title}</Title>
      </Header>
      <ScrollWrapper>
        <FadeOut left={fadeOutLeft} right={fadeOutRight}>
          <ScrollLeft
            onClick={scrollLeft}
            className={fadeOutLeft ? CSS.canScroll : CSS.atScrollEnd}
          >
            <PointerLeft />
          </ScrollLeft>
          <div className={CSS.coverRow} ref={coverRowRef} onScroll={onScroll}>
            {issues.map((issue) => {
              const { id, bnlId, coverImage, publishedDate } = issue;

              return (
                <IssueCover
                  key={id + bnlId}
                  onClick={() => onClick(issue)}
                  coverImage={coverImage}
                  publicationDate={publishedDate}
                />
              );
            })}
          </div>
          <ScrollRight
            onClick={scrollRight}
            className={fadeOutRight ? CSS.canScroll : CSS.atScrollEnd}
          >
            <PointerRight />
          </ScrollRight>
        </FadeOut>
      </ScrollWrapper>
    </Container>
  );
};

HorizontalIssueScroll.propTypes = {
  title: node.isRequired,
  onIssueClick: func.isRequired,
  initialScrollPosition: number,
  issues: arrayOf(
    shape({
      id: string.isRequired,
      bnlId: string.isRequired,
      coverImage: shape({
        url: string.isRequired,
        size: shape({
          width: number.isRequired,
          height: number.isRequired,
        }).isRequired,
      }).isRequired,
      publishedDate: string.isRequired,
    }),
  ).isRequired,
};

HorizontalIssueScroll.defaultProps = {
  initialScrollPosition: 0,
};

export default HorizontalIssueScroll;
