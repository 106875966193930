import moment from 'moment';
import { useMemo } from 'react';

const formatPublicationDate = date =>
  moment(date).calendar(null, {
    sameElse: 'D MMMM YYYY',
  });

const useFormattedPublicationDate = date => useMemo(() => formatPublicationDate(date), [date]);

export default useFormattedPublicationDate;
