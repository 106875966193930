export const DEFAULT_NUMBER_OF_ITEMS_PER_ROW_ON_Y_AXIS = 2;

export const determineScrollPosition = ({
  orientation,
  positionOfIssue,
  numberOfItemsPerRowOnYAxis = DEFAULT_NUMBER_OF_ITEMS_PER_ROW_ON_Y_AXIS,
}) =>
  orientation === 'vertical'
    ? Math.floor(positionOfIssue / numberOfItemsPerRowOnYAxis)
    : positionOfIssue;
