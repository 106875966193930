// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loading-4XDxN{position:fixed;top:50%;left:50%}.loading-4XDxN:after{background:#d3d3d3}\n", ""]);
// Exports
exports.locals = {
	"loading": "loading-4XDxN"
};
module.exports = exports;
