import React, { useEffect, useState } from 'react';
import { string, shape, func } from 'prop-types';
import { STATUS_OK, STATUS_ERROR, INTERNAL_LOCATION } from 'app-constants';
import PagesContainer from 'modules/issue/containers/PagesIssueContainer';
import useAlt from 'hooks/useAlt';
import useGlobalClassName from 'hooks/useGlobalClassName';
import PremiumIssueActions from 'actions/PremiumIssueActions';
import PremiumIssueStore from 'stores/PremiumIssueStore';
import Error from 'components/Application/Error';
import { InternalLocationContext } from 'context/internalLocationContext';
import useUser from 'hooks/useUser';
import Loading from 'components/discover/Loading';
import IssueNavigation from 'components/discover/IssueNavigation';
import Analytics from 'instances/analytics';

const PagesIssueContainer = ({
  provider,
  issue: issueDetails,
  updateCurrentPageAndOpenProviderArchive,
  onBackFromIssueView,
}) => {
  const { bnlId } = issueDetails;
  const { status, issue } = useAlt([PremiumIssueStore], ([{ issues }]) => issues.get(bnlId) || {});
  const user = useUser();
  const [currentSectionName, setCurrentSectionName] = useState();

  useGlobalClassName('m-issue');

  useEffect(() => {
    if (!status) {
      PremiumIssueActions.fetchIssue({ bnlId, userId: user.id });
    }
  }, [bnlId, status]);

  const renderCustomNavigation = (sections, currentSection) => {
    const sectionsDropdown =
      sections && sections.length > 1
        ? {
            label: `${provider.name}: ${currentSection.name}`,
            sections: sections.map(({ name, pages_index: pagesIndex }) => ({
              label: name,
              isActive: name === currentSection.name,
              onClick: () => {
                Analytics.track('Go To Issue Section', {
                  provider: provider.name,
                  issue_id: bnlId,
                  section: `${name}(${pagesIndex})`,
                });

                setCurrentSectionName(name);
              },
            })),
          }
        : undefined;

    return (
      <IssueNavigation
        data-testid="issue-navigation"
        onBack={onBackFromIssueView}
        onMoreFromProvider={() => {
          updateCurrentPageAndOpenProviderArchive({
            provider,
            issue: issueDetails,
          });
        }}
        sectionsDropdown={sectionsDropdown}
      />
    );
  };

  switch (status) {
    case STATUS_OK:
      return (
        <InternalLocationContext.Provider
          value={{ internalLocation: INTERNAL_LOCATION.PAGED_ISSUE_VIEW }}
        >
          <PagesContainer
            data-testid="pages-issue"
            issue={issue}
            renderCustomNavigation={renderCustomNavigation}
            section={currentSectionName}
          />
        </InternalLocationContext.Provider>
      );
    case STATUS_ERROR:
      return <Error data-testid="error-component" />;
    default:
      return <Loading data-testid="loading-component" />;
  }
};

PagesIssueContainer.propTypes = {
  provider: shape({
    id: string.isRequired,
    name: string.isRequired,
    providerId: string.isRequired,
  }).isRequired,
  issue: shape({
    id: string.isRequired,
    bnlId: string.isRequired,
  }).isRequired,
  updateCurrentPageAndOpenProviderArchive: func.isRequired,
  onBackFromIssueView: func.isRequired,
};

export default PagesIssueContainer;
