// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".imageWrapper-2Bh8c{box-shadow:0 4px 20px rgba(0,0,0,0.25),0 4px 10px rgba(0,0,0,0.08);border-radius:2px}@keyframes fadeIn-OJ2kA{from{opacity:0}to{opacity:1}}.publicationDate-XVUYS{font-family:\"GT-Walsheim\",sans-serif;letter-spacing:-0.02em;font-size:12px;color:#5b5b5b;position:relative;top:7px;cursor:default;opacity:0;animation:fadeIn-OJ2kA 0.3s 0s ease-in forwards}.publicationDate-XVUYS:first-letter{text-transform:capitalize}@media (min-width: 600px){.publicationDate-XVUYS{font-size:14px;top:13px}}\n", ""]);
// Exports
exports.locals = {
	"imageWrapper": "imageWrapper-2Bh8c",
	"publicationDate": "publicationDate-XVUYS",
	"fadeIn": "fadeIn-OJ2kA"
};
module.exports = exports;
