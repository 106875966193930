// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-2kwak{width:50px;position:relative;height:calc(100% + 40px);z-index:200;background:#e3e3e3;border:none;border-right:1px solid #ccc;margin-top:-20px;margin-bottom:-20px;text-align:center}.navItem-3H49X{height:44px;line-height:44px}.backButton-1M9LX:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA04\";font-size:22px;color:#948784}.moreFromButton-1kl9z:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA0A\";font-size:22px;color:#948784}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2kwak",
	"navItem": "navItem-3H49X",
	"backButton": "backButton-1M9LX",
	"moreFromButton": "moreFromButton-1kl9z"
};
module.exports = exports;
