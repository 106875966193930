import React, { useEffect } from 'react';
import { Button, Dialog, DialogBody } from '@blendle/lego';
import styledModule from 'helpers/styledModule';
import staticImageUrl from 'helpers/staticImageUrl';
import { translate, translateElement } from 'instances/i18n';
import { INTERNAL_LOCATION } from 'app-constants';
import Analytics from 'instances/analytics';
import { bool, func } from 'prop-types';
import CSS from './styles.scss';

const imagePath = staticImageUrl('/img/illustrations/CreditsKioskCovers-v2');

const StyledDialog = styledModule(Dialog)(CSS.dialog);
const Body = styledModule(DialogBody)(CSS.dialogBody);
const InnerBody = styledModule.div(CSS.innerBody);
const CopyWrapper = styledModule.div(CSS.copyWrapper);
const Title = styledModule.h1(CSS.title);
const Illustration = styledModule.img(CSS.illustration);
const Text = styledModule.p(CSS.text);
const CtaWrapper = styledModule.div(CSS.ctaWrapper);
const Cta = styledModule(Button)(CSS.cta);

const NewKioskDialog = ({ showDialog, dismissDialog }) => {
  useEffect(() => {
    if (showDialog) {
      Analytics.track('Credit Explanation Shown', {
        internal_location: INTERNAL_LOCATION.CREDIT_KIOSK,
      });
    }
  }, [showDialog]);

  const onClose = () => {
    dismissDialog();
  };

  return (
    <StyledDialog fullScreen open={showDialog} onClose={onClose}>
      <Body>
        <InnerBody>
          <CopyWrapper>
            <Illustration
              src={`${imagePath}@2x.png?auto=format,compress`}
              srcSet={`${imagePath}.png?auto=format,compress 1x, ${imagePath}@2x.png?auto=format,compress 2x`}
              alt="Illustration with newspapers and magazines covers"
            />
            <Title>{translate('kiosk.credits.welcome_dialog.title')}</Title>
            <Text>{translateElement('kiosk.credits.welcome_dialog.text')}</Text>
          </CopyWrapper>
          <CtaWrapper>
            <Cta color="razzmatazz" onClick={onClose}>
              {translate('kiosk.credits.welcome_dialog.cta')}
            </Cta>
          </CtaWrapper>
        </InnerBody>
      </Body>
    </StyledDialog>
  );
};

NewKioskDialog.propTypes = {
  showDialog: bool.isRequired,
  dismissDialog: func.isRequired,
};

export default NewKioskDialog;
