import { useEffect } from 'react';

function addGlobalClassName(className) {
  document.body.classList.add(className);
}

function removeGlobalClassName(className) {
  document.body.classList.remove(className);
}

const useGlobalClassName = className => {
  useEffect(() => {
    addGlobalClassName(className);

    return () => {
      removeGlobalClassName(className);
    };
  }, [className]);
};

export default useGlobalClassName;
