import React, { useEffect } from 'react';
import { string, func, oneOf } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { prop } from 'ramda';
import Loading from 'components/discover/Loading';
import { ISSUE_QUERY } from 'queries';
import { throwError } from 'higher-order-components/withErrorBoundary';
import CreditsKioskNavigationStore from 'stores/CreditsKioskNavigationStore';
import DiscoverNavigationStore from 'stores/DiscoverNavigationStore';
import useStorePages from 'hooks/useStorePages';
import TilesIssueContainer from './TilesIssueContainer';
import PagesIssueContainer from './PagesIssueContainer';

const issue = prop('node');

const IssueContainer = ({
  graphQLIssueId,
  onBackFromIssueView,
  saveCurrentPage,
  updateCurrentPageAndOpenProviderArchive,
  store,
}) => {
  const { loading, error, data } = useQuery(ISSUE_QUERY, {
    variables: {
      id: graphQLIssueId,
    },
  });

  const { previousPage, isIssuePageActive } = useStorePages(store);

  useEffect(() => {
    saveCurrentPage();
  }, []);

  if (loading) {
    return <Loading data-testid="issue-container-loading-component" />;
  }

  if (error || !issue(data)) {
    return throwError(error || new Error('Failed fetching issue', graphQLIssueId));
  }

  const { browsable, bnlId, articleIds, provider, id } = issue(data);

  return browsable ? (
    <PagesIssueContainer
      data-testid="pages-issue-container"
      provider={provider}
      issue={{
        id,
        bnlId,
      }}
      onBackFromIssueView={() => onBackFromIssueView(previousPage)}
      updateCurrentPageAndOpenProviderArchive={updateCurrentPageAndOpenProviderArchive}
    />
  ) : (
    <TilesIssueContainer
      data-testid="tiles-issue-container"
      provider={provider}
      issue={{
        id,
        bnlId,
        itemIds: articleIds,
      }}
      onBackFromIssueView={() => onBackFromIssueView(previousPage)}
      updateCurrentPageAndOpenProviderArchive={updateCurrentPageAndOpenProviderArchive}
      isActive={isIssuePageActive}
    />
  );
};

IssueContainer.propTypes = {
  graphQLIssueId: string.isRequired,
  onBackFromIssueView: func.isRequired,
  saveCurrentPage: func.isRequired,
  updateCurrentPageAndOpenProviderArchive: func.isRequired,
  store: oneOf([CreditsKioskNavigationStore, DiscoverNavigationStore]).isRequired,
};

export default IssueContainer;
