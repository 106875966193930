// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".reset-jYHaW{height:auto;min-height:100%;background:white}.reset-jYHaW #application,.reset-jYHaW #app,.reset-jYHaW .a-main{height:auto;min-height:100%;background:white}.reset-jYHaW .a-footer{display:none}\n", ""]);
// Exports
exports.locals = {
	"reset": "reset-jYHaW"
};
module.exports = exports;
