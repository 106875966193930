import { gql } from 'apollo-boost';
import { GlobalFragments, IssueFragments } from 'queries/fragments';
import { DEFAULT_ISSUES_PER_PAGE } from 'app-constants';

export const RECENT_ISSUES_QUERY = gql`
  query RecentIssues($first: Int = ${DEFAULT_ISSUES_PER_PAGE}, $after: String) {
    recentIssues(first: $first, after: $after) {
      pageInfo {
        ...pageInfoFragment
      }
      nodes {
        ...issueFragment
      }
    }
  }
  ${GlobalFragments.pageInfo}
  ${IssueFragments.issue}
`;
