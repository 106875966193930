import React, { useState } from 'react';
import WebIssueTile from 'components/tiles/WebIssueTile';
import TilePane from 'components/TilePane';
import Measure from 'react-measure';
import { getItemId } from 'selectors/item';
import { bool, array, func, oneOf, node } from 'prop-types';
import styledModule from 'helpers/styledModule';
import { INTERNAL_LOCATION } from 'app-constants';
import { isEmpty } from 'ramda';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);

const TilesIssuePage = ({
  loading,
  tiles,
  onNearEnd,
  orientation,
  showButtons,
  mobileNavigation,
  isActive,
}) => {
  const [paneHeight, setPaneHeight] = useState(null);

  const renderTiles = () => {
    if (isEmpty(tiles) || !paneHeight) {
      return null;
    }

    return tiles.map((item) => {
      const itemId = getItemId(item);

      return (
        <WebIssueTile
          key={itemId}
          itemId={itemId}
          tileHeight={paneHeight}
          internalLocation={INTERNAL_LOCATION.PAGED_ISSUE_VIEW}
        />
      );
    });
  };

  return (
    <Container>
      <Measure client onResize={({ client }) => setPaneHeight(client.height)}>
        {({ measureRef }) => (
          <TilePane
            data-testid="tile-pane"
            innerRef={measureRef}
            showButtons={showButtons}
            loading={loading}
            orientation={orientation}
            onNearEnd={onNearEnd}
            active={isActive}
          >
            {mobileNavigation}
            {renderTiles()}
          </TilePane>
        )}
      </Measure>
    </Container>
  );
};

TilesIssuePage.propTypes = {
  loading: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tiles: array.isRequired,
  onNearEnd: func.isRequired,
  orientation: oneOf(['horizontal', 'vertical']).isRequired,
  showButtons: bool.isRequired,
  mobileNavigation: node,
  isActive: bool.isRequired,
};

TilesIssuePage.defaultProps = {
  mobileNavigation: undefined,
};

export default TilesIssuePage;
