// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1R5W_{margin-top:25px}@media (min-width: 600px){.container-1R5W_{margin-top:40px}}.header-1jP_c{padding-left:16px;padding-right:16px;position:relative;height:21px;margin-bottom:-6px}.title-2-IqF{font-family:\"GT-Walsheim\",sans-serif;font-weight:600;letter-spacing:-0.03em;color:#000;font-size:18px;position:absolute;z-index:1}@media (min-width: 600px){.title-2-IqF{font-size:22px}}.canScroll-oQe8i,.atScrollEnd-3NxtY{opacity:0;transition:opacity 0.2s ease-out}.scrollButton-2kv1Z{width:56px;height:56px;background:#fff;border:1px solid rgba(0,0,0,0.1);box-shadow:0 4px 8px rgba(0,0,0,0.16);border-radius:28px;position:absolute;z-index:1000;top:112px}.scrollButton-2kv1Z.canScroll-oQe8i:hover{cursor:pointer;box-shadow:0 6px 8px rgba(0,0,0,0.2);transform:translateY(-1px)}.scrollWrapper-3DuQM{position:relative}.scrollWrapper-3DuQM:hover .scrollButton-2kv1Z{transition:opacity 0.2s ease-in}.scrollWrapper-3DuQM:hover .canScroll-oQe8i{opacity:1}.scrollWrapper-3DuQM:hover .atScrollEnd-3NxtY{opacity:0.5}@media (hover: none){.scrollWrapper-3DuQM .scrollButton-2kv1Z{display:none}}.scrollRight-Q9Rpt{right:32px}.scrollLeft-1t3-K{left:32px}.pointer-N5I9e{position:relative;top:2px;color:#333}.coverRow-2akgY{display:flex;overflow-x:auto;-webkit-overflow-scrolling:touch;padding:16px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1R5W_",
	"header": "header-1jP_c",
	"title": "title-2-IqF",
	"canScroll": "canScroll-oQe8i",
	"atScrollEnd": "atScrollEnd-3NxtY",
	"scrollButton": "scrollButton-2kv1Z",
	"scrollWrapper": "scrollWrapper-3DuQM",
	"scrollRight": "scrollRight-Q9Rpt",
	"scrollLeft": "scrollLeft-1t3-K",
	"pointer": "pointer-N5I9e",
	"coverRow": "coverRow-2akgY"
};
module.exports = exports;
