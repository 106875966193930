// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-8CY8b{padding-bottom:32px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-8CY8b"
};
module.exports = exports;
