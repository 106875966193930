import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { string, func, oneOf } from 'prop-types';
import BrowserEnv from 'instances/browser_environment';
import Analytics from 'instances/analytics';
import { IS_NETWORK_STATUS } from 'selectors/graphQL';
import { fetchMoreResults } from 'helpers/graphQL/fetchMoreResults';
import { GRAPHQL_QUERY_NAME, INTERNAL_LOCATION, DISCOVER_PAGE_TYPE } from 'app-constants';
import {
  pipe,
  propOr,
  head,
  prop,
  isNil,
  not,
  filter,
  includes,
  __,
  propEq,
  findIndex,
} from 'ramda';
import { history } from 'byebye';
import { IssuesPage } from 'components/discover/IssuesPage';
import SubNavBarNavigation from 'components/discover/SubNavBarNavigation';
import { PROVIDER_ISSUES_QUERY } from 'queries';
import { throwError } from 'higher-order-components/withErrorBoundary';
import CreditsKioskNavigationStore from 'stores/CreditsKioskNavigationStore';
import useStorePages from 'hooks/useStorePages';
import DiscoverNavigationStore from 'stores/DiscoverNavigationStore';
import {
  determineScrollPosition,
  DEFAULT_NUMBER_OF_ITEMS_PER_ROW_ON_Y_AXIS,
} from 'helpers/scrollPosition';
import SegmentAnalytics from 'instances/segmentAnalytics';

const internalLocation = INTERNAL_LOCATION.DISCOVER_ISSUE_ARCHIVE;

const WHITE_LISTED_ERRORS = [
  /* It is possible that the graphQL api returns "null" instead of returning an issue.
   * This can happen for instance if an issue is never opened before by any user.
   * Despite this error we still want to show the issues that are queried successfully
   */
  'GraphQL error: Cannot return null for non-nullable field Issue.articleIds',
];

const isWhiteListedError = pipe(prop('message'), includes(__, WHITE_LISTED_ERRORS));

const getProviderFromData = pipe(propOr([], GRAPHQL_QUERY_NAME.NODES), head);
const issuesFromData = pipe(getProviderFromData, prop('issues'));
const providerIdFromData = pipe(getProviderFromData, prop('providerId'));
const pageInfo = pipe(issuesFromData, propOr({}, 'pageInfo'));
const isNotNil = pipe(isNil, not);
const filterEmpty = filter(isNotNil);
const issuesNodes = pipe(issuesFromData, propOr([], 'nodes'), filterEmpty);

const navigate = (page) =>
  history.navigate(page, {
    trigger: true,
  });

const updateQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  const previousProvider = getProviderFromData(previousResult);
  const { issues: previousProviderIssues } = previousProvider;
  const incomingProviderIssues = issuesFromData(fetchMoreResult);

  return {
    [GRAPHQL_QUERY_NAME.NODES]: [
      {
        ...previousProvider,
        issues: {
          ...previousProviderIssues,
          nodes: [...previousProviderIssues.nodes, ...incomingProviderIssues.nodes],
          pageInfo: incomingProviderIssues.pageInfo,
        },
      },
    ],
  };
};

const ProviderIssuesContainer = ({
  graphQLProviderId,
  baseRoute,
  saveCurrentPage,
  updateCurrentPage,
  removeLastPage,
  store,
}) => {
  const [shownEventSent, setShownEventSent] = useState(false);

  const { loading, error, data, networkStatus, fetchMore } = useQuery(PROVIDER_ISSUES_QUERY, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    variables: {
      ids: [graphQLProviderId],
    },
  });

  const { currentPage, previousPage } = useStorePages(store);

  useEffect(() => {
    saveCurrentPage({ type: DISCOVER_PAGE_TYPE.PROVIDER_ISSUES });
  }, []);

  if (!shownEventSent && providerIdFromData(data)) {
    const properties = {
      internal_location: internalLocation,
      provider_id: providerIdFromData(data),
    };

    Analytics.track('Issue Archive Shown', properties);
    SegmentAnalytics.page({
      pageName: 'Issue Archive',
      properties,
    });

    setShownEventSent(true);
  }

  const orientation = useMemo(() => (BrowserEnv.isMobile() ? 'vertical' : 'horizontal'), []);

  if (error) {
    if (isWhiteListedError(error) && data) {
      window.ErrorLogger.captureMessage(
        'Encountered whitelisted error while fetching provider issues',
        {
          error: JSON.stringify(error.message),
          graphQLProviderId,
        },
      );
    } else {
      return throwError(error);
    }
  }

  const issues = issuesNodes(data);

  const onNearEnd = () => {
    if (IS_NETWORK_STATUS.ready(networkStatus)) {
      fetchMoreResults({
        fetchMore,
        pageInfo: pageInfo(data),
        updateQuery,
      });
    }
  };

  const sendViewIssueEvent = (viewIssueProperties) => {
    Analytics.track('View Issue', viewIssueProperties);
    SegmentAnalytics.page({
      pageName: 'Issue Browser',
      properties: viewIssueProperties,
    });
  };

  const onIssueClick = (issue) => {
    const { bnlId, provider, id } = issue;

    const positionOfIssue = findIndex(propEq('id', id), issues);

    updateCurrentPage({
      provider: {
        providerId: provider.providerId,
        id: provider.id,
      },
      type: 'provider-issues',
      scrollPosition: determineScrollPosition({
        orientation,
        positionOfIssue,
      }),
    });

    sendViewIssueEvent({
      issue_id: bnlId,
      provider_id: provider.providerId,
      position: positionOfIssue,
      internal_location: internalLocation,
    });

    navigate(`${baseRoute}/${provider.id}/${id}`);
  };

  const onBack = () => {
    removeLastPage();

    if (previousPage.provider && previousPage.issue) {
      sendViewIssueEvent({
        issue_id: previousPage.issue.bnlId,
        provider_id: previousPage.provider.providerId,
        internal_location: internalLocation,
      });

      navigate(`${baseRoute}/${previousPage.provider.id}/${previousPage.issue.id}`);
    } else {
      navigate(baseRoute);
    }
  };

  return (
    <>
      <SubNavBarNavigation onBack={onBack} data-testid="navigation" />
      <IssuesPage
        data-testid="provider-issues-page"
        issues={issues}
        loading={loading}
        onIssueClick={onIssueClick}
        onNearEnd={onNearEnd}
        orientation={orientation}
        addSpaceForSubNavigation
        scrollPosition={currentPage.scrollPosition}
        numberOfItemsPerRowOnYAxis={DEFAULT_NUMBER_OF_ITEMS_PER_ROW_ON_Y_AXIS}
      />
    </>
  );
};

ProviderIssuesContainer.propTypes = {
  graphQLProviderId: string.isRequired,
  baseRoute: string.isRequired,
  updateCurrentPage: func.isRequired,
  saveCurrentPage: func.isRequired,
  removeLastPage: func.isRequired,
  store: oneOf([CreditsKioskNavigationStore, DiscoverNavigationStore]).isRequired,
};

export default ProviderIssuesContainer;
