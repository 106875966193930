import React from 'react';
import { withErrorBoundary } from 'higher-order-components/withErrorBoundary';
import { object } from 'prop-types';
import { path, anyPass, equals, pipe } from 'ramda';

import features from 'config/features';
import useDidDismissNewCreditKioskDialog from 'hooks/useDidDismissNewCreditKioskDialog';
import SingleCategoryRecentIssuesContainer from './SingleCategoryRecentIssuesContainer';
import MultipleCategoriesRecentIssueContainer from './MultipleCategoriesRecentIssueContainer';
import NewKioskDialog from '../components/NewKioskDialog';
import PaymentSuccessDialog from '../components/PaymentSuccessDialog';

const didCreditsTopup = pipe(
  path(['query', 'didCreditsTopup']),
  anyPass([equals(true), equals('true')]),
);

const RecentIssuesRootContainer = ({ location }) => {
  const showPaymentSuccessDialog = didCreditsTopup(location);
  const {
    showDialog: showNewCreditsKioskDialog,
    dismissDialog,
  } = useDidDismissNewCreditKioskDialog();

  return (
    <>
      {showPaymentSuccessDialog && <PaymentSuccessDialog />}
      <NewKioskDialog
        data-testid="new-kiosk-dialog"
        showDialog={showNewCreditsKioskDialog}
        dismissDialog={dismissDialog}
      />
      {features.showCategoriesInKiosk() ? (
        <MultipleCategoriesRecentIssueContainer />
      ) : (
        <SingleCategoryRecentIssuesContainer
          showPaymentSuccessDialog={showPaymentSuccessDialog}
          showNewCreditsKioskDialog={showNewCreditsKioskDialog}
        />
      )}
    </>
  );
};

RecentIssuesRootContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: object,
};

RecentIssuesRootContainer.defaultProps = {
  location: undefined,
};

export default withErrorBoundary(RecentIssuesRootContainer);
