// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-1KyxO{width:100%;max-width:1112px;margin:0 auto;padding:0;background:#fff;padding-bottom:80px}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-1KyxO"
};
module.exports = exports;
