import React from 'react';
import { func, shape, arrayOf, string, bool } from 'prop-types';
import BrowserEnvironment from 'instances/browser_environment';
import { translate } from 'instances/i18n';
import SubNavBarNavigation from '../SubNavBarNavigation';
import VerticalTilePaneNavigation from '../VerticalTilePaneNavigation';

const IssueNavigation = ({ onBack, onMoreFromProvider, sectionsDropdown }) =>
  BrowserEnvironment.isMobile() ? (
    <VerticalTilePaneNavigation
      onBack={onBack}
      onMoreFromProvider={onMoreFromProvider}
      data-testid="mobile-navigation"
    />
  ) : (
    <SubNavBarNavigation
      onBack={onBack}
      onMoreFromProvider={onMoreFromProvider}
      data-testid="desktop-navigation"
      moreFromProviderCopy={translate('app.issue.previous_editions')}
      sectionsDropdown={sectionsDropdown}
    />
  );

IssueNavigation.propTypes = {
  onBack: func.isRequired,
  onMoreFromProvider: func.isRequired,
  sectionsDropdown: shape({
    label: string.isRequired,
    sections: arrayOf(
      shape({
        label: string.isRequired,
        onClick: func.isRequired,
        isActive: bool.isRequired,
      }),
    ).isRequired,
  }),
};

IssueNavigation.defaultProps = {
  sectionsDropdown: undefined,
};

export default IssueNavigation;
