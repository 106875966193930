import React, { useState } from 'react';
import { func, arrayOf, shape, string, bool } from 'prop-types';
import { ArrowDownIcon } from '@blendle/lego';
import styledModule from 'helpers/styledModule';
import SharedCSS from '../shared.scss';
import CSS from './styles.scss';

const NavItem = styledModule.div(SharedCSS.navItem);
const DropdownWrapper = styledModule.div(CSS.dropdownWrapper);
const DropdownList = styledModule('ul')(CSS.dropdownList);
const SectionLink = styledModule.button(CSS.sectionLink);
const StyledArrowDownIcon = styledModule(ArrowDownIcon)(CSS.arrowDownIcon);

const SectionsDropdown = ({ label, sections }) => {
  const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsSectionDropdownOpen(isOpen => !isOpen);
  };

  const columns = Math.ceil(sections.length / 8);

  return (
    <DropdownWrapper>
      <NavItem
        onClick={toggleDropdown}
        data-testid="dropdown-link"
        className={isSectionDropdownOpen ? CSS.isActive : ''}
      >
        {label}
        <StyledArrowDownIcon width={10} />
      </NavItem>
      <DropdownList
        className={isSectionDropdownOpen ? CSS.isOpen : CSS.isClosed}
        data-testid="dropdown-list"
        style={{ columnCount: columns }}
      >
        {sections.map(({ label: childLabel, onClick, isActive }) => (
          <li key={childLabel}>
            <SectionLink
              onClick={() => {
                onClick();
                toggleDropdown();
              }}
              className={isActive ? CSS.isActive : ''}
            >
              {childLabel}
            </SectionLink>
          </li>
        ))}
      </DropdownList>
    </DropdownWrapper>
  );
};

SectionsDropdown.propTypes = {
  label: string.isRequired,
  sections: arrayOf(
    shape({
      label: string.isRequired,
      onClick: func.isRequired,
      isActive: bool.isRequired,
    }),
  ).isRequired,
};

export default SectionsDropdown;
