/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Label, Typography } from '@blendle/lego';
import { shape, string, number, bool } from 'prop-types';

import styledModule from 'helpers/styledModule';
import BalanceLabel from 'components/BalanceLabel';
import { translate, translateElement } from 'instances/i18n';
import { MAX_DISCOVER_TILE_HEIGHT } from 'app-constants';
import Link from 'components/Link';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);
Container.displayName = 'IntroTitle__Container';

const Title = styledModule(Typography)(CSS.title);
Title.displayName = 'IntroTitle__Title';

const Paragraph = styledModule(Typography)(CSS.paragraph);
Container.displayName = 'IntroTitle__Container';

const ParagraphMobile = styledModule(Paragraph)(CSS.paragraphMobile);
ParagraphMobile.displayName = 'IntroTitle__ParagraphMobile';

const ParagraphDesktop = styledModule(Paragraph)(CSS.paragraphDesktop);
ParagraphDesktop.displayName = 'IntroTitle__ParagraphDesktop';

const BalanceWrapper = styledModule.div(CSS.balanceWrapper);
BalanceWrapper.displayName = 'IntroTitle__BalanceWrapper';

const Balance = styledModule(Link)(CSS.balance);
Balance.displayName = 'IntroTitle__Balance';

const StyledBalanceLabel = styledModule(BalanceLabel)(CSS.balanceLabel);
StyledBalanceLabel.displayName = 'IntroTitle__StyledBalanceLabel';

const NewLabel = styledModule(Label)(CSS.newLabel);
NewLabel.displayName = 'IntroTitle__NewLabel';

const IntroTile = ({ wrapperClassName, labelClassName }) => (
  <Container
    style={{ maxHeight: MAX_DISCOVER_TILE_HEIGHT }}
    className={wrapperClassName}
    data-testid="intro-tile"
  >
    <NewLabel color="snowWhite" backgroundGradient="razzmatazzGradient" data-testid="label">
      {translate('navigation.new')}
    </NewLabel>
    <Title variant="h1" data-testid="title">
      {translate('kiosk.credits.intro.title')}
    </Title>
    <ParagraphDesktop data-testid="paragraph-desktop">
      {translateElement('kiosk.credits.intro.text_desktop')}
    </ParagraphDesktop>
    <ParagraphMobile data-testid="paragraph-mobile">
      {translateElement('kiosk.credits.intro.text_mobile')}
    </ParagraphMobile>
    <BalanceWrapper>
      <Balance href="/settings/credits" data-testid="balance">
        {translate('kiosk.credits.intro.balance')}
        <StyledBalanceLabel className={labelClassName} />
      </Balance>
    </BalanceWrapper>
  </Container>
);

IntroTile.defaultProps = {
  axis: null,
  availableSize: null,
  visible: false,
  wrapperClassName: undefined,
  labelClassName: undefined,
};

IntroTile.propTypes = {
  /* AvailableSize, Axis  and Visible props are injected by ScrollList, but not needed for the Intro tile */
  availableSize: shape({
    width: number,
    height: number,
  }),
  axis: string,
  visible: bool,
  wrapperClassName: string,
  labelClassName: string,
};

export default IntroTile;
