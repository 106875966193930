import React from 'react';
import { withErrorBoundary } from 'higher-order-components/withErrorBoundary';
import withRouter from 'react-router/lib/withRouter';
import { shape, string } from 'prop-types';
import { compose } from 'ramda';
import ProviderIssuesContainer from 'containers/discover/ProviderIssuesContainer';
import CreditsKioskNavigationActions from 'actions/CreditsKioskNavigationActions';
import CreditsKioskNavigationStore from 'stores/CreditsKioskNavigationStore';

const ProviderIssuesRootContainer = ({ params }) => {
  const { graphQLProviderId } = params;

  return (
    <ProviderIssuesContainer
      graphQLProviderId={graphQLProviderId}
      baseRoute="kiosk/issue"
      saveCurrentPage={CreditsKioskNavigationActions.saveCurrentPage}
      updateCurrentPage={CreditsKioskNavigationActions.updateCurrentPage}
      removeLastPage={CreditsKioskNavigationActions.removeLastPage}
      store={CreditsKioskNavigationStore}
      data-testid="provider-issues"
    />
  );
};

ProviderIssuesRootContainer.propTypes = {
  params: shape({
    graphQLProviderId: string.isRequired,
  }).isRequired,
};

const enhance = compose(withErrorBoundary, withRouter);

export default enhance(ProviderIssuesRootContainer);
