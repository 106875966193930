// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".intro-12UhS{position:relative;text-align:center}.confetti-2Orwk{position:static;width:100%;height:175px}.fadeOut-giQV5{position:absolute;background:linear-gradient(to top, #fff 5%, rgba(255,255,255,0) 100%);display:block;width:100%;top:131px;height:50px}.title-1WpnD{position:absolute;font-family:\"GT-Walsheim\",sans-serif;color:#ff1060;font-size:46px;line-height:46px;margin-top:90px;top:0;width:100%}.paragraph-Kavkl{font-family:\"GT-Walsheim\",sans-serif;font-size:16px;line-height:22px;letter-spacing:-0.02em}.button-X4ayR{font-weight:500;width:100%}\n", ""]);
// Exports
exports.locals = {
	"intro": "intro-12UhS",
	"confetti": "confetti-2Orwk",
	"fadeOut": "fadeOut-giQV5",
	"title": "title-1WpnD",
	"paragraph": "paragraph-Kavkl",
	"button": "button-X4ayR"
};
module.exports = exports;
