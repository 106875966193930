import React from 'react';
import { func, node, arrayOf, shape, string, bool } from 'prop-types';
import styledModule from 'helpers/styledModule';
import { translate } from 'instances/i18n';
import CSS from './styles.scss';
import SharedCSS from './shared.scss';
import SectionsDropdown from './SectionsDropdown';

const Container = styledModule.div(CSS.container);
const NavItem = styledModule.div(SharedCSS.navItem);
const BackButton = styledModule(NavItem)(CSS.backButton);
const MoreFromButton = styledModule(NavItem)(CSS.moreFromButton);

const SubNavBarNavigation = ({
  onBack,
  onMoreFromProvider,
  moreFromProviderCopy,
  sectionsDropdown,
}) => (
  <Container>
    <BackButton onClick={onBack}>{translate('app.buttons.back')}</BackButton>
    {moreFromProviderCopy && onMoreFromProvider ? (
      <MoreFromButton onClick={onMoreFromProvider} data-testid="provider-archive-link">
        {moreFromProviderCopy}
      </MoreFromButton>
    ) : null}
    {sectionsDropdown && <SectionsDropdown {...sectionsDropdown} />}
  </Container>
);

SubNavBarNavigation.propTypes = {
  onBack: func.isRequired,
  onMoreFromProvider: func,
  moreFromProviderCopy: node,
  sectionsDropdown: shape({
    label: string.isRequired,
    sections: arrayOf(
      shape({
        label: string.isRequired,
        onClick: func.isRequired,
        isActive: bool.isRequired,
      }),
    ).isRequired,
  }),
};

SubNavBarNavigation.defaultProps = {
  onMoreFromProvider: undefined,
  moreFromProviderCopy: undefined,
  sectionsDropdown: undefined,
};

export default SubNavBarNavigation;
