// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loadingWrapper-11IrI{position:relative;display:flex;justify-content:center;align-items:center;margin-top:20px;height:50px}.spinner-1aY1L:after{background:#d3d3d3}\n", ""]);
// Exports
exports.locals = {
	"loadingWrapper": "loadingWrapper-11IrI",
	"spinner": "spinner-1aY1L"
};
module.exports = exports;
