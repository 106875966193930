import React from 'react';
import { string, number, shape, func } from 'prop-types';

import BrowserEnv from 'instances/browser_environment';
import Tile from 'components/Tile';
import Image from 'components/Image';
import { useMemoizedCoverTileStyles } from 'helpers/coverTile';
import styledModule from 'helpers/styledModule';
import useFormattedPublicationDate from 'hooks/useFormattedPublicationDate';
import CSS from './styles.scss';

const ImageWrapper = styledModule.div(CSS.imageWrapper);
const PublicationDate = styledModule.div(CSS.publicationDate);

export const CoverTile = ({
  axis,
  availableSize,
  onClick,
  coverImage,
  numberOfItemsPerRowOnYAxis,
  publicationDate,
}) => {
  const {
    size: { width: coverWidth, height: coverHeight },
  } = coverImage;

  const style = useMemoizedCoverTileStyles({
    axis,
    availableSize,
    coverWidth,
    coverHeight,
    numberOfItemsPerRowOnYAxis,
  });

  const formattedPublicationDate = useFormattedPublicationDate(publicationDate);

  return (
    <Tile type="cover">
      <ImageWrapper className="v-cover cover-image" onClick={onClick} role="button" style={style}>
        <Image src={coverImage.url} animate={BrowserEnv.isDesktop()} />
      </ImageWrapper>
      <PublicationDate>{formattedPublicationDate}</PublicationDate>
    </Tile>
  );
};

CoverTile.defaultProps = {
  axis: null,
  availableSize: null,
};

CoverTile.propTypes = {
  /* AvailableSize and Axis props are injected by ScrollList */
  // eslint-disable-next-line react/require-default-props
  availableSize: shape({
    width: number.isRequired,
    height: number.isRequired,
  }),
  // eslint-disable-next-line react/require-default-props
  axis: string,
  coverImage: shape({
    url: string.isRequired,
    size: shape({
      width: number.isRequired,
      height: number.isRequired,
    }).isRequired,
  }).isRequired,
  onClick: func.isRequired,
  numberOfItemsPerRowOnYAxis: number.isRequired,
  publicationDate: string.isRequired,
};
