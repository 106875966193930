// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-Qhm9a{from{opacity:0}to{opacity:1}}.container-Bed50{width:100%;margin-top:19px;padding-left:16px;padding-right:16px;height:100%;opacity:0;animation:fadeIn-Qhm9a 0.3s 0s ease-in forwards}@media (min-width: 600px){.container-Bed50{max-width:422px;margin-top:0;margin-right:10px;padding-left:16px;padding-right:16px}}.newLabel-1puRP.newLabel-1puRP{height:18px;text-transform:uppercase;margin-bottom:9px;border-radius:3px;font-weight:700;font-size:10px;line-height:12px}.title-2283t{font-family:\"GT-Walsheim\",sans-serif;font-size:22px;line-height:26px;margin-bottom:1px;letter-spacing:-0.03em;color:#000}@media (min-width: 600px){.title-2283t{font-size:40px;line-height:42px;margin-bottom:8px}}.paragraph-143SN{font-family:\"GT-Walsheim\",sans-serif;font-size:16px;line-height:22px;letter-spacing:-0.02em;color:#5b5b5b;margin-bottom:15px}@media (min-width: 600px){.paragraph-143SN{font-size:20px;line-height:28px;margin-bottom:24px}}.paragraphMobile-1Cshm{display:block}@media (min-width: 600px){.paragraphMobile-1Cshm{display:none}}.paragraphDesktop-o-jXR{display:none}@media (min-width: 600px){.paragraphDesktop-o-jXR{display:block}}.balanceWrapper-3y_iN{padding-bottom:14px;margin-bottom:6px;border-bottom:1px solid #e5e5e5}@media (min-width: 600px){.balanceWrapper-3y_iN{border-bottom:none}}.balance-1Gt4k{font-family:\"GT-Walsheim\",sans-serif;font-size:16px;line-height:22px;letter-spacing:-0.02em;color:#7f7f7f;text-decoration:none}.balanceLabel-17NJa.balanceLabel-17NJa{height:20px;line-height:20px;margin-left:4px;position:relative;top:-1px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-Bed50",
	"fadeIn": "fadeIn-Qhm9a",
	"newLabel": "newLabel-1puRP",
	"title": "title-2283t",
	"paragraph": "paragraph-143SN",
	"paragraphMobile": "paragraphMobile-1Cshm",
	"paragraphDesktop": "paragraphDesktop-o-jXR",
	"balanceWrapper": "balanceWrapper-3y_iN",
	"balance": "balance-1Gt4k",
	"balanceLabel": "balanceLabel-17NJa"
};
module.exports = exports;
