import React from 'react';
import { bool, oneOf, func, string, shape, number, node, arrayOf } from 'prop-types';
import TilePane from 'components/TilePane';
import styledModule from 'helpers/styledModule';
import { CoverTile } from 'components/discover/CoverTile';
import CSS from './styles.scss';

const StyledTilePane = styledModule(TilePane)(CSS.tilePane);

export const IssuesPage = ({
  issues,
  loading,
  onIssueClick,
  onNearEnd,
  orientation,
  addSpaceForSubNavigation,
  introTile,
  lockHorizontalScroll,
  scrollPosition,
  numberOfItemsPerRowOnYAxis,
}) => (
  <>
    {orientation === 'vertical' && introTile}
    <StyledTilePane
      className="kiosk-list-discover"
      loading={loading}
      orientation={orientation}
      onNearEnd={onNearEnd}
      data-testid="tile-pane"
      addSpaceForSubNavigation={addSpaceForSubNavigation}
      numberOfItemsPerRowOnYAxis={numberOfItemsPerRowOnYAxis}
      active={!lockHorizontalScroll}
      initialIndex={scrollPosition}
    >
      {orientation === 'horizontal' && introTile}
      {issues.map((issue) => {
        const { id, bnlId, coverImage, publishedDate } = issue;

        return (
          <CoverTile
            key={id + bnlId}
            onClick={() => onIssueClick(issue)}
            coverImage={coverImage}
            data-testid="cover-tile"
            numberOfItemsPerRowOnYAxis={numberOfItemsPerRowOnYAxis}
            publicationDate={publishedDate}
          />
        );
      })}
    </StyledTilePane>
  </>
);

IssuesPage.propTypes = {
  lockHorizontalScroll: bool,
  loading: bool.isRequired,
  orientation: oneOf(['horizontal', 'vertical']).isRequired,
  onNearEnd: func.isRequired,
  onIssueClick: func.isRequired,
  issues: arrayOf(
    shape({
      id: string.isRequired,
      bnlId: string.isRequired,
      coverImage: shape({
        url: string.isRequired,
        size: shape({
          width: number.isRequired,
          height: number.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  ),
  addSpaceForSubNavigation: bool.isRequired,
  introTile: node,
  scrollPosition: number,
  numberOfItemsPerRowOnYAxis: number,
};

IssuesPage.defaultProps = {
  issues: [],
  introTile: undefined,
  lockHorizontalScroll: false,
  scrollPosition: undefined,
  numberOfItemsPerRowOnYAxis: 2,
};
