import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { history } from 'byebye';
import { string, oneOf, func, node, bool, number } from 'prop-types';
import { pathOr, findIndex, propEq } from 'ramda';

import BrowserEnv from 'instances/browser_environment';
import { IS_NETWORK_STATUS } from 'selectors/graphQL';
import { INTERNAL_LOCATION } from 'app-constants';
import { fetchMoreResults } from 'helpers/graphQL/fetchMoreResults';
import useEventOnMount from 'hooks/useEventOnMount';
import Analytics from 'instances/analytics';
import { IssuesPage } from 'components/discover/IssuesPage';
import { KIOSK_ISSUES_QUERY } from 'modules/creditsKiosk/queries';
import { RECENT_ISSUES_QUERY } from 'modules/discover/issue/queries';
import { throwError } from 'higher-order-components/withErrorBoundary';
import {
  determineScrollPosition,
  DEFAULT_NUMBER_OF_ITEMS_PER_ROW_ON_Y_AXIS,
} from 'helpers/scrollPosition';
import usePageEventOnMount from 'hooks/usePageEventOnMount';
import SegmentAnalytics from 'instances/segmentAnalytics';

const getUpdateQueryFunction = (queryName) => (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  return {
    [queryName]: {
      ...previousResult[queryName],
      pageInfo: fetchMoreResult[queryName].pageInfo,
      nodes: [...previousResult[queryName].nodes, ...fetchMoreResult[queryName].nodes],
    },
  };
};

const RecentIssuesContainer = ({
  baseRoute,
  query,
  queryName,
  internalLocation,
  mountEventName,
  pageEventName,
  saveRecentIssuesPage,
  introTile,
  lockHorizontalScroll,
  scrollPosition,
}) => {
  const properties = {
    internal_location: internalLocation,
  };
  useEventOnMount(mountEventName, properties);
  usePageEventOnMount({ pageName: pageEventName, properties });

  const getPageInfo = pathOr({}, [queryName, 'pageInfo']);
  const getNodes = pathOr([], [queryName, 'nodes']);

  const { loading, error, data, fetchMore, networkStatus } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
  });

  const orientation = useMemo(() => (BrowserEnv.isMobile() ? 'vertical' : 'horizontal'), []);

  if (error) {
    return throwError(error);
  }

  const issues = getNodes(data);

  const onNearEnd = () => {
    if (IS_NETWORK_STATUS.ready(networkStatus)) {
      fetchMoreResults({
        fetchMore,
        pageInfo: getPageInfo(data),
        updateQuery: getUpdateQueryFunction(queryName),
      });
    }
  };

  const onIssueClick = (issue) => {
    const { bnlId, provider, id } = issue;
    const positionOfIssue = findIndex(propEq('id', id), issues);

    saveRecentIssuesPage(determineScrollPosition({ orientation, positionOfIssue }));

    const viewIssueProperties = {
      internal_location: internalLocation,
      issue_id: bnlId,
      provider_id: provider.providerId,
      position: positionOfIssue,
    };
    Analytics.track('View Issue', viewIssueProperties);
    SegmentAnalytics.page({
      pageName: 'Issue Browser',
      properties: viewIssueProperties,
    });

    history.navigate(`${baseRoute}/${provider.id}/${id}`, {
      trigger: true,
    });
  };

  return (
    <IssuesPage
      data-testid="recent-issues-page"
      issues={issues}
      loading={loading}
      onIssueClick={onIssueClick}
      onNearEnd={onNearEnd}
      orientation={orientation}
      addSpaceForSubNavigation={false}
      introTile={introTile}
      lockHorizontalScroll={lockHorizontalScroll}
      scrollPosition={scrollPosition}
      numberOfItemsPerRowOnYAxis={DEFAULT_NUMBER_OF_ITEMS_PER_ROW_ON_Y_AXIS}
    />
  );
};

RecentIssuesContainer.propTypes = {
  baseRoute: string.isRequired,
  query: oneOf([RECENT_ISSUES_QUERY, KIOSK_ISSUES_QUERY]).isRequired,
  queryName: string.isRequired,
  internalLocation: oneOf([INTERNAL_LOCATION.DISCOVER_PAGE, INTERNAL_LOCATION.CREDIT_KIOSK])
    .isRequired,
  mountEventName: string.isRequired,
  pageEventName: string.isRequired,
  saveRecentIssuesPage: func.isRequired,
  introTile: node,
  lockHorizontalScroll: bool,
  scrollPosition: number,
};

RecentIssuesContainer.defaultProps = {
  introTile: undefined,
  lockHorizontalScroll: false,
  scrollPosition: undefined,
};

export default RecentIssuesContainer;
