import { equals } from 'ramda';

export const NETWORK_STATUS = {
  LOADING: 1,
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  POLL: 6,
  READY: 7,
  ERROR: 8,
};

// See https://github.com/apollographql/apollo-client/blob/master/src/core/networkStatus.ts
// for a full list of all GraphQL network states
export const IS_NETWORK_STATUS = {
  initialLoading: equals(NETWORK_STATUS.LOADING),
  fetchMoreLoading: equals(NETWORK_STATUS.FETCH_MORE),
  ready: equals(NETWORK_STATUS.READY),
};
