import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { findIndex, propEq } from 'ramda';

import resetStyles from 'higher-order-components/resetStyles';
import HorizontalIssueScroll from 'components/discover/HorizontalIssueScroll';
import Loader from 'components/Loader';
import { translate } from 'instances/i18n';
import { throwError } from 'higher-order-components/withErrorBoundary';
import history from 'libs/byebye/history';
import SegmentAnalytics from 'instances/segmentAnalytics';
import Analytics from 'instances/analytics';
import { INTERNAL_LOCATION } from 'app-constants';
import { MULTIPLE_CATEGORIES_ISSUES_QUERY } from '../queries';
import { NewKioskIntroTile } from '../components/NewKioskIntroTile';
import RecentIssuesPage from '../components/RecentIssuesPage';

export const MAX_NUMBER_OF_ISSUES = 50;

const MultipleCategoriesRecentIssueContainer = () => {
  const onIssueClick = (issues) => ({ id, bnlId, provider }) => {
    function dispatchAnalyticsEvents() {
      const positionOfIssue = findIndex(propEq('id', id), issues);
      const viewIssueProperties = {
        internal_location: INTERNAL_LOCATION.CREDIT_KIOSK,
        issue_id: bnlId,
        provider_id: provider.providerId,
        position: positionOfIssue,
      };

      Analytics.track('View Issue', viewIssueProperties);

      SegmentAnalytics.page({
        pageName: 'Issue Browser',
        properties: viewIssueProperties,
      });
    }

    function openIssuePage() {
      history.navigate(`${history.currentLocation.pathname}/${provider.id}/${id}`, {
        trigger: true,
      });
    }

    dispatchAnalyticsEvents();
    openIssuePage();
  };

  const { data, error } = useQuery(MULTIPLE_CATEGORIES_ISSUES_QUERY, {
    variables: { first: MAX_NUMBER_OF_ISSUES },
  });

  if (error) {
    return throwError(error);
  }

  const generateIssuesCategories = () => [
    {
      title: translate('languages.nl'),
      issues: data.dutchIssues.nodes,
    },
    {
      title: translate('languages.en'),
      issues: data.englishIssues.nodes,
    },
    {
      title: translate('languages.de'),
      issues: data.germanIssues.nodes,
    },
  ];

  return (
    <RecentIssuesPage data-testid="recent-issues-page">
      <NewKioskIntroTile />
      {data ? (
        generateIssuesCategories().map((category) => (
          <HorizontalIssueScroll
            key={category.title}
            title={category.title}
            issues={category.issues}
            onIssueClick={onIssueClick(category.issues)}
          />
        ))
      ) : (
        <Loader />
      )}
    </RecentIssuesPage>
  );
};

export default resetStyles(MultipleCategoriesRecentIssueContainer);
